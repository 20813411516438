<template>
    <router-view v-if="isRouterAlive"></router-view>
</template>

<script>
export default {
    data() {
        return {
            isRouterAlive: true,
        };
    },
    provide() {
        return {
            reload: this.reload,
        };
    },
    methods: {
        startHacking() {
            // this.$api.demo.getDemo().then((res) => {
            //   console.log(res);
            // });

            // this.$notify({
            //     title: "It works!",
            //     type: "success",
            //     message:
            //         "We've laid the ground work for you. It's time for you to build something epic!",
            //     duration: 5000,
            // });

            console.log(this.$util.date.getToday());
        },
        reload: function() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        },
    },
};
</script>

<style lang="scss">
#app {
    font-family: Helvetica, sans-serif;
    text-align: center;
}

body {
    margin: 0;
}

.wrap {
    padding: 20px;
    box-sizing: border-box;
}
// 开单页面的autocomplete样式（不知道为什么，写到scoped里引用不到，搞清楚后可以放进order/CreateOrder里）
.my-autocomplete {
    li {
        line-height: normal;
        padding: 7px;

        .name {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .phone {
            font-size: 12px;
            color: #b4b4b4;
        }

        .highlighted .phone {
            color: #ddd;
        }
    }
}
// 隐藏页面内的面包屑
.breadcrumb-hide {
    display: none;
}
// 原价
.old-price {
    text-decoration: line-through;
    color: var(--gray);
}
</style>
