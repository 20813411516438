const install = (Vue) => {
    if (!Vue.prototype.$util) {
        // 初始化 $api
        Vue.prototype.$util = {}
    }

    // 对Date的扩展，将 Date 转化为指定格式的String
    // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
    // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
    // 例子： 
    // (new Date()).format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423 
    // (new Date()).format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
    Date.prototype.format = function (fmt) {
        var o = {
            "M+": this.getMonth() + 1, //月份 
            "d+": this.getDate(), //日 
            "h+": this.getHours(), //小时 
            "m+": this.getMinutes(), //分 
            "s+": this.getSeconds(), //秒 
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
            "S": this.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" +
                o[k]).substr(("" + o[k]).length)));
        return fmt;
    }
    /**
     * 日期格式化
     */
    const format = (date, fmt = 'yyyy-MM-dd') => {
        return new Date(date).format(fmt)
    }
    /**
     * 获取今天日期
     */
    const getToday = () => {
        var day = new Date();
        var today = day.format('yyyy-MM-dd'); //今天
        return {
            startDate: today,
            endDate: today
        };
    }
    /**
     * 获取昨天日期
     */
    const getYesterday = () => {
        var yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).format('yyyy-MM-dd'); //昨天
        return {
            startDate: yesterday,
            endDate: yesterday
        };
    }
    /**
     * 获取本月开始结束日期
     */
    const getThisMonth = () => {
        //本月第一天和本月最后一天
        var thismonth1 = new Date(new Date().setDate(1)).format('yyyy-MM-dd');
        var thismonth2 = new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).format('yyyy-MM-dd');
        return {
            startDate: thismonth1,
            endDate: thismonth2
        }
    }
    /**
     * 获取上月开始结束日期
     */
    const getLastMonth = () => {
        //上一月第一天和最后一天          
        var lastmonth1 = new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).format('yyyy-MM-dd');
        var lastmonth2 = new Date(new Date().setDate(0)).format('yyyy-MM-dd');
        return {
            startDate: lastmonth1,
            endDate: lastmonth2
        }
    }
    /**
     * 获取本周开始结束日期
     */
    const getThisWeek = () => {
        //本周的第一天和最后一天
        var weekday = new Date().getDay() || 7;
        var thisweek1 = new Date(new Date().setDate(new Date().getDate() - weekday + 1)).format('yyyy-MM-dd');
        var thisweek2 = new Date(new Date().setDate(new Date().getDate() - weekday + 7)).format('yyyy-MM-dd');
        return {
            startDate: thisweek1,
            endDate: thisweek2
        }
    }
    /**
     * 获取上周开始结束日期
     */
    const getLastWeek = () => {
        var weekday = new Date().getDay() || 7;
        //上周的第一天和最后一天
        var lastweek1 = new Date(new Date().setDate(new Date().getDate() - weekday - 6)).format('yyyy-MM-dd');
        var lastweek2 = new Date(new Date().setDate(new Date().getDate() - weekday)).format('yyyy-MM-dd')
        return {
            startDate: lastweek1,
            endDate: lastweek2
        }
    }
    /**
     * 获取本季度开始结束日期
     */
    const getThisSeason = () => {
        //本季度第一天和最后一天
        var month = new Date().getMonth();
        var newmonth
        var newmonth1
        if (month < 3) {
            newmonth = new Date(new Date().setMonth(0))
            newmonth1 = new Date(new Date().setMonth(3))
        } else if (2 < month && month < 6) {
            newmonth = new Date(new Date().setMonth(3))
            newmonth1 = new Date(new Date().setMonth(6))
        } else if (5 < month && month < 9) {
            newmonth = new Date(new Date().setMonth(6))
            newmonth1 = new Date(new Date().setMonth(9))
        } else if (8 < month && month < 11) {
            newmonth = new Date(new Date().setMonth(9))
            newmonth1 = new Date(new Date().setMonth(12))
        }
        var thisseason1 = new Date(newmonth.setDate(1)).format('yyyy-MM-dd')
        var thisseason2 = new Date(newmonth1.setDate(0)).format('yyyy-MM-dd')
        return {
            startDate: thisseason1,
            endDate: thisseason2
        }
    }
    /**
     * 获取上季度开始结束日期
     */
    const getLastSeason = () => {
        var y = new Date().getFullYear(); //当前年份
        var m = new Date().getMonth(); //当前月份
        var q = parseInt(m / 3); //当前季度
        var qs = new Date(y, (q - 1) * 3, 1); //上一季度的开始日期
        var qe = new Date(y, q * 3, 0); //上一季度的结束日期
        return {
            startDate: qs.format('yyyy-MM-dd'),
            endDate: qe.format('yyyy-MM-dd')
        }
    }
    //上半年第一天和最后一天
    // var firsthalf1 = new Date(new Date(new Date().setMonth(0)).setDate(1)).format('yyyy-MM-dd')
    // var firsthalf2 = new Date(new Date(new Date().setMonth(6)).setDate(0)).format('yyyy-MM-dd')

    //下半年第一天和最后一天
    // var secondehalf1 = new Date(new Date(new Date().setMonth(6)).setDate(1)).format('yyyy-MM-dd')
    // var secondehalf2 = new Date(new Date(new Date().setMonth(12)).setDate(0)).format('yyyy-MM-dd')
    /**
     * 时间戳 转 天小时分钟
     * @param {number} my_time 时间戳
     */
    const getDuration = (my_time) => {
        var days = my_time / 1000 / 60 / 60 / 24;
        var daysRound = Math.floor(days);
        var hours = my_time / 1000 / 60 / 60 - (24 * daysRound);
        var hoursRound = Math.floor(hours);
        var minutes = my_time / 1000 / 60 - (24 * 60 * daysRound) - (60 * hoursRound);
        var minutesRound = Math.floor(minutes);
        var seconds = my_time / 1000 - (24 * 60 * 60 * daysRound) - (60 * 60 * hoursRound) - (60 * minutesRound);
        // console.log('转换时间:', daysRound + '天', hoursRound + '时', minutesRound + '分', seconds + '秒');
        // var time = hoursRound + ':' + minutesRound + ':' + seconds
        return {
            days,
            daysRound,
            hours,
            hoursRound,
            minutes,
            minutesRound,
            seconds
        };
    }
    /**
     * 获取生日距离今天还有多少天
     * @param {string} birthday 生日
     * @param {string} today 今天的日期
     */
    const getNextBirthdayDays = (birthday, today) => {
        // 生日时间
        let s1 = new Date(birthday.replace(/-/g, "/"));
        // 今天时间
        let s2 = new Date(today.replace(/-/g, "/"));
        // 生日时间设置为今年
        s1.setFullYear(s2.getFullYear())
        // 获取间隔时间
        let jiange = dateDifference(s1.format('yyyy/MM/dd'), s2.format('yyyy/MM/dd'));
        // 判断间隔时间如果为负数
        if (jiange < 0) {
            // 生日时间设置为明年
            s1.setFullYear(s2.getFullYear() + 1);
            // 获取间隔时间
            jiange = dateDifference(s1.format('yyyy/MM/dd'), s2.format('yyyy/MM/dd'));
            return jiange;
        } else {
            return jiange;
        }
    }
    /**
     * 计算天数差
     */
    const dateDifference = (sDate1, sDate2) => {
        var dateSpan,
            iDays;
        sDate1 = Date.parse(sDate1);
        sDate2 = Date.parse(sDate2);
        dateSpan = sDate2 - sDate1;
        dateSpan = Math.abs(dateSpan);
        iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
        return iDays
    };

    /* 
     * 获得时间差,时间格式为 年-月-日 小时:分钟:秒 或者 年/月/日 小时：分钟：秒，其中，年月日为全格式，例如 ： 2010-10-12 01:00:00 。返回精度为：秒，分，小时，天
     */
    const getDateDiff = (startTime, endTime, diffType) => {
        //将xxxx-xx-xx的时间格式，转换为 xxxx/xx/xx的格式 
        startTime = startTime.replace(/-/g, "/");
        endTime = endTime.replace(/-/g, "/");

        //将计算间隔类性字符转换为小写
        diffType = diffType.toLowerCase();
        var sTime = new Date(startTime); //开始时间
        var eTime = new Date(endTime); //结束时间
        //作为除数的数字
        var divNum = 1;
        switch (diffType) {
            case "second":
                divNum = 1000;
                break;
            case "minute":
                divNum = 1000 * 60;
                break;
            case "hour":
                divNum = 1000 * 3600;
                break;
            case "day":
                divNum = 1000 * 3600 * 24;
                break;
            default:
                break;
        }
        return parseInt((eTime.getTime() - sTime.getTime()) / parseInt(divNum));
    }
    // 挂载
    Vue.prototype.$util.date = {
        getToday,
        getYesterday,
        getThisWeek,
        getLastWeek,
        getThisMonth,
        getLastMonth,
        getThisSeason,
        getLastSeason,
        getDuration,
        getNextBirthdayDays,
        dateDifference,
        getDateDiff,
        format
    }
}

export default {
    install
}