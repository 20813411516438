const install = (Vue, axios) => {

	// 服务卡
	// 保存
	let saveServe = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/serve/card/edit', params)
		} else {
			return axios.post('/api/merchant/serve/card/add', params)
		}
	};
	// 设置上下线
	let setServeStatus = (params = {}) => axios.post('/api/merchant/serve/card/status/edit', params)
	// 获取列表
	let getServes = (params = {}) => axios.post('/api/merchant/serve/card/list', params)
	// 获取单条
	let getServeById = (params = {}) => axios.post('/api/merchant/serve/card/info', params)


	// 充值卡
	// 保存
	let saveRecharge = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/recharge/card/edit', params)
		} else {
			return axios.post('/api/merchant/recharge/card/add', params)
		}
	};
	// 设置上下线
	let setRechargeStatus = (params = {}) => axios.post('/api/merchant/recharge/card/status/edit', params)
	// 获取列表
	let getRecharges = (params = {}) => axios.post('/api/merchant/recharge/card/list', params)
	// 获取单条
	let getRechargeById = (params = {}) => axios.post('/api/merchant/recharge/card/info', params)

	// 过去服务标签列表 标签类型 0 商品 1 服务
	let getTags = (params = {
		type: 1
	}) => axios.post('/api/merchant/product/tag/list', params)
    // 获取店铺所有会员卡  name 卡名
	let getVipCards = (params = {}) => axios.post('/api/merchant/vip/getMerchantVipList', params)

	// 挂载
	Vue.prototype.$api.card = {
        getVipCards,
		saveServe,
		getServes,
		getServeById,
		setServeStatus,
		saveRecharge,
		getRecharges,
		getRechargeById,
		setRechargeStatus,
		getTags
	};
}

export default {
	install
}
