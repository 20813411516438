export default {
	install(Vue) {
		Vue.filter('toFixed2', function(value) {
			if (isNaN(Number(value))) {
				return ''
			} else {
				return Number(value).toFixed(2);
			}
		})
		Vue.filter('phoneStar', function(tel) {
			return tel ? tel.substr(0, 3) + '****' + tel.substr(7) : '';
		})
	}
}
