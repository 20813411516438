const install = (Vue, vm) => {
	// 请求拦截
	Vue.prototype.axios.interceptors.request.use(
		config => {
			// console.log(config)
			// 对请求做点什么
			// 设置token
			if (vm.vuex_token) {
				config.headers.common['Authorization'] = vm.vuex_token;
			}
			return config;
		}, error => {
			// 对请求错误做些什么
			return Promise.reject(error);
		}
	);
	// 响应拦截
	Vue.prototype.axios.interceptors.response.use(
		response => {
			return response.data;
		},
		error => {
			if (error.response) {
				console.log(error.response);
				switch (error.response.status) {
					case 401:
						// 未登录处理，跳转到登录
						vm.$router.replace('/login')
						break;
				}
			}
			return Promise.reject(error.response) // 返回接口返回的错误信息
		}
	);
}

export default {
	install
}
