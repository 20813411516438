// 公共接口
import commonApi from './api.common'
import userApi from './api.user'
import systemApi from './api.system'
import classifyApi from './api.classify'
import projectApi from './api.project'
import goodsApi from './api.goods'
import staffApi from './api.staff'
import memberApi from './api.member'
import cardApi from './api.card'
import orderApi from './api.order'
import stockApi from './api.stock'
import statisticsApi from './api.statistics'
import storeApi from './api.store'
import groupApi from './api.group'
import tableApi from './api.table'
import tablewxApi from './api.tablewx'
import marketingApi from './api.marketing'
import platformActivityApi from './api.platformActivity'


const install = (Vue, axios) => {
	// 初始化 $api 
	Vue.prototype.$api = {}
	// 挂载
	Vue.use(commonApi, axios)
	Vue.use(userApi, axios)
	Vue.use(systemApi, axios)
	Vue.use(classifyApi, axios)
	Vue.use(projectApi, axios)
	Vue.use(goodsApi, axios)
	Vue.use(staffApi, axios)
	Vue.use(memberApi, axios)
	Vue.use(cardApi, axios)
	Vue.use(orderApi, axios)
	Vue.use(stockApi, axios)
	Vue.use(statisticsApi, axios)
	Vue.use(storeApi, axios)
	Vue.use(groupApi, axios)
	Vue.use(tableApi, axios)
	Vue.use(tablewxApi, axios)
	Vue.use(marketingApi, axios)
	Vue.use(platformActivityApi, axios)
}

export default {
	install
}
