const install = (Vue, axios) => {
	const UPLOAD_URL = '/api/merchant/upload/img'
	const IMPORT_MEMBER_URL = '/api/merchant/member/importMember'
	// 腾讯地图的key
	const TMAP_KEY = 'CEEBZ-BEJLW-7PKRP-OC3II-EYTV2-GPFP6'
	/**
	 * 删除图片
	 * @param {string} path 图片路径
	 */
	let delImage = (params = {}) => axios.post('/api/merchant/move/upload/img', params)
	/**
	 * 品牌列表
	 */
	let getBrandList = (params = {}) => axios.post('/api/merchant/set/meal/getServeBrandList', params)
	
	
	// 挂载
	Vue.prototype.$api.common = {
		UPLOAD_URL,
		IMPORT_MEMBER_URL, // 导入会员接口地址
		TMAP_KEY,
		delImage,
		getBrandList
	};
}

export default {
	install
}
