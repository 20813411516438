// 会员相关接口
const install = (Vue, axios) => {
	
	// 挂载
	Vue.prototype.$api.marketing = {
	
		// 获取拼团列表
		getGroupList: (params = {}) => axios.post('/api/merchant/team/list', params),
		// 保存拼团信息
		saveGroup : (params = {}) => axios.post('/api/merchant/team/updateOrCreate', params),
		// 获取拼团信息
		getGroupById : (params = {}) => axios.post('/api/merchant/team/info', params),
		// 获取会员列表
		getUserList : (params = {}) => axios.post('/api/merchant/team/user/list', params),
		// 导出会员列表
		exportUserList : (params = {}) => axios.post('/api/merchant/team/user/export', params,{responseType: 'arraybuffer'}),
		// 获取订单列表
		getOrderList : (params = {}) => axios.post('/api/merchant/Promotion/order/list', params),
		// 导出订单列表
		exportOrderList : (params = {}) => axios.post('/api/merchant/Promotion/order/export', params,{responseType: 'arraybuffer'}),
		// 获取订单详情
		getOrderDetails : (params = {}) => axios.post('/api/merchant/Promotion/order/info', params),
	};
}

export default {
	install,
}
