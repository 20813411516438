// 会员相关接口
const install = (Vue, axios) => {
	// 获取新客咨询列表
	let newConsultList = (params = {}) => axios.post('/api/merchant/table/consult/list', params);
	// 保存新客咨询
	let saveNewConsult = (params = {}) => axios.post('/api/merchant/table/consult/add', params);
	// 获取新客咨询详情
	let newConsultDetails = (params = {}) => axios.post('/api/merchant/table/consult/info', params);
	// 删除新客咨询
	let delNewConsult = (params = {}) => axios.post('/api/merchant/table/consult/del', params);
	// 获取客户预约记录列表
	let appointmentList = (params = {}) => axios.post('/api/merchant/table/appointment/list', params);
	// 保存客户预约记录
	let saveAppointment = (params = {}) => axios.post('/api/merchant/table/appointment/add', params);
	// 获取客户预约记录详情
	let appointmentDetails = (params = {}) => axios.post('/api/merchant/table/appointment/info', params);
	// 删除客户预约记录
	let delAppointment  = (params = {}) => axios.post('/api/merchant/table/appointment/del', params);
	// 获取人员工资一览表列表
	let wagesList = (params = {}) => axios.post('/api/merchant/table/wages/list', params);
	// 保存人员工资一览表
	let saveWages = (params = {}) => axios.post('/api/merchant/table/wages/add', params);
	// 获取人员工资一览表详情
	let wagesDetails = (params = {}) => axios.post('/api/merchant/table/wages/info', params);
	// 删除人员工资一览表
	let delWages  = (params = {}) => axios.post('/api/merchant/table/wages/del', params);
	// 获取营业报表列表
	let businessList = (params = {}) => axios.post('/api/merchant/table/business/list', params);
	// 保存营业报表
	let saveBusiness = (params = {}) => axios.post('/api/merchant/table/business/add', params);
	// 获取营业报表详情
	let businessDetails = (params = {}) => axios.post('/api/merchant/table/business/info', params);
	// 删除营业报表
	let delBusiness  = (params = {}) => axios.post('/api/merchant/table/business/del', params);
	// 获取现金月报列表
	let cashMonthList = (params = {}) => axios.post('/api/merchant/table/cashMonth/list', params);
	// 保存现金月报
	let saveCashMonth = (params = {}) => axios.post('/api/merchant/table/cashMonth/add', params);
	// 获取现金月报详情
	let cashMonthDetails = (params = {}) => axios.post('/api/merchant/table/cashMonth/info', params);
	// 删除现金月报
	let delCashMonth  = (params = {}) => axios.post('/api/merchant/table/cashMonth/del', params);
	// 获取月份工资核算列表
	let wagesTotalList = (params = {}) => axios.post('/api/merchant/table/wagesTotal/list', params);
	// 保存月份工资核算
	let saveWagesTotal = (params = {}) => axios.post('/api/merchant/table/wagesTotal/add', params);
	// 获取月份工资核算详情
	let wagesTotalDetails = (params = {}) => axios.post('/api/merchant/table/wagesTotal/info', params);
	// 删除月份工资核算
	let delWagesTotal  = (params = {}) => axios.post('/api/merchant/table/wagesTotal/del', params);
	// 获取财务报表列表
	let financeList = (params = {}) => axios.post('/api/merchant/table/finance/list', params);
	// 保存财务报表
	let saveFinance = (params = {}) => axios.post('/api/merchant/table/finance/add', params);
	// 获取财务报表详情
	let financeDetails = (params = {}) => axios.post('/api/merchant/table/finance/info', params);
	// 删除财务报表
	let delFinance  = (params = {}) => axios.post('/api/merchant/table/finance/del', params);
	// 数据导出-新客咨询
	let consultExport  = (params = {}) => axios.post('/api/merchant/table/consult/export', params,{responseType: 'arraybuffer'});
	// 数据导出-客户预约记录
	let appointmentExport  = (params = {}) => axios.post('/api/merchant/table/appointment/export', params,{responseType: 'arraybuffer'});
	// 数据导出-人员工资一览表
	let wagesExport  = (params = {}) => axios.post('/api/merchant/table/wages/export', params,{responseType: 'arraybuffer'});
	// 数据导出-营业报表
	let businessExport  = (params = {}) => axios.post('/api/merchant/table/business/export', params,{responseType: 'arraybuffer'});
	// 数据导出-现金月报
	let cashMonthExport  = (params = {}) => axios.post('/api/merchant/table/cashMonth/export', params,{responseType: 'arraybuffer'});
	// 数据导出-月份工资核算
	let wagesTotalExport  = (params = {}) => axios.post('/api/merchant/table/wagesTotal/export', params,{responseType: 'arraybuffer'});
	// 数据导出-财务报表
	let financeExport  = (params = {}) => axios.post('/api/merchant/table/finance/export', params,{responseType: 'arraybuffer'});

	// 挂载
	Vue.prototype.$api.table = {
		newConsultList,
		saveNewConsult,
		delNewConsult,
		newConsultDetails,
		appointmentList,
		saveAppointment,
		delAppointment,
		appointmentDetails,
		wagesList,
		saveWages,
		delWages,
		wagesDetails,
		businessList,
		saveBusiness,
		delBusiness,
		businessDetails,
		cashMonthList,
		saveCashMonth,
		delCashMonth,
		cashMonthDetails,
		wagesTotalList,
		saveWagesTotal,
		delWagesTotal,
		wagesTotalDetails,
		financeList,
		saveFinance,
		financeDetails,
		delFinance,
		consultExport,
		appointmentExport,
		wagesExport,
		businessExport,
		cashMonthExport,
		wagesTotalExport,
		financeExport,
		// 获取会员列表
		getMemberList: (params = {}) => axios.post('/api/merchant/table/member/list', params),
		// 根据id获取会员信息
		getMemberById: (params = {}) => axios.post('/api/merchant/table/member/info', params),
		// getMemberBy: (params = {}) => axios.post('/api/merchant/table/member/info', params),
		// 保存会员信息
		saveMember: (params = {}) => axios.post('/api/merchant/table/member/edit', params),
		// 获取客户档案列表
		getCustomerList: (params = {}) => axios.post('/api/merchant/table/customer/list', params),
		// 根据id获取可会档案信息
		getCustomerById: (params = {}) => axios.post('/api/merchant/table/customer/info', params),
		// 保存客户档案
		saveCustomer: (params = {}) => {
			if (params.id) {
				return axios.post('/api/merchant/table/customer/edit', params)
			} else {
				return axios.post('/api/merchant/table/customer/add', params)
			}
		},
		// 删除
		delCustomerById: (params = {}) => axios.post('/api/merchant/table/customer/del', params),
		// 保存消费记录
		saveConsume: (params = {}) => axios.post('/api/merchant/table/customer/itemAdd', params),
		// 删除消费记录
		delConsumeById: (params = {}) => axios.post('/api/merchant/table/customer/itemDel', params),

		//产品领域报表
		getCollectList: (params = {}) => axios.post('/api/merchant/table/collectList', params),
		// 保存产品领域报表
		Addcollect: (params = {}) => {
			if (params.id) {
				return axios.post('/api/merchant/table/collectEdit', params)
			} else {
				return axios.post('/api/merchant/table/collectAdd', params)
			}
		},

		//删除产品领域报表
		collectDel: (params = {}) => axios.post('/api/merchant/table/collectDel', params),

		//详情产品领域报表
		collectDetail: (params = {}) => axios.post('/api/merchant/table/collectDetail', params),

		//导出产品领域报表
		collectExport: (params = {}) => axios.post('/api/merchant/table/collectExport', params, {
			responseType: 'arraybuffer'
		}),

		//库存月报表
		stockList: (params = {}) => axios.post('/api/merchant/table/stockList', params),

		//导出库存月报表
		stockExport: (params = {}) => axios.post('/api/merchant/table/stockExport', params, {
			responseType: 'arraybuffer'
		}),

		//促销活动总表
		getActivityList: (params = {}) => axios.post('/api/merchant/table/activityList', params),

		//添加促销活动总表
		addActivity: (params = {}) => {
			if (params.id) {
				return axios.post('/api/merchant/table/activityEdit', params)
			} else {
				return axios.post('/api/merchant/table/activityAdd', params)
			}
		},
		
		
		//删除促销活动总表
		activityDel: (params = {}) => axios.post('/api/merchant/table/activityDel', params),
		
		//导出促销活动总表
		activityExport: (params = {}) => axios.post('/api/merchant/table/activityExport', params, {
			responseType: 'arraybuffer'
		}),
		
		//详情促销活动总表
		activityDetail: (params = {}) => axios.post('/api/merchant/table/activityDetail', params),
		
		
	
	};
}

export default {
	install
}
