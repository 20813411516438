// 商品相关接口
const install = (Vue, axios) => {

	// 获取自营列表
	let getSelfGoods = (params = {}) => axios.post('/api/merchant/offline/goods/list', params)

	// 获取品牌商品列表
	let getBrandGoods = (params = {}) => axios.post('/api/merchant/brand/product/specs/list', params)


	// 更新库存
	let update = (params = {}) => axios.post('/api/merchant/product/update/stock', params)
	// 出入库记录
	let log = (params = {}) => axios.post('/api/merchant/product/stock/log/list', params)

	// 挂载
	Vue.prototype.$api.stock = {
		getSelfGoods,
		getBrandGoods,
		update,
		log
	};
}

export default {
	install
}
