// 商品相关接口
const install = (Vue, axios) => {


	// 保存
	let save = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/offline/goods/edit', params)
		} else {
			return axios.post('/api/merchant/offline/goods/add', params)
		}
	};
	// 获取列表
	let getGoods = (params = {}) => axios.post('/api/merchant/offline/goods/list', params)
	// 获取单条
	let getGoodsById = (params = {}) => axios.post('/api/merchant/offline/goods/info', params)
	// 删除列表
	let del = (params = {}) => axios.post('/api/merchant/offline/goods/del', params)


	// 获取品牌商品列表
	let getBrandGoods = (params = {}) => axios.post('/api/merchant/brand/product/list', params)

	// 根据商品编码查找商品
	let getGoodsByCode = (params = {}) => axios.post('/api/merchant/product/code/info', params)
	
	// 品牌商品上下架
	let setBrandStatus = (params = {}) => axios.post('/api/merchant/brand/product/status/edit', params)
	
	// 修改销售提成
	let saveSaleCommission = (params = {}) => axios.post('/api/merchant/set/brand/product/sale/commission', params)

	// 挂载
	Vue.prototype.$api.goods = {
		save,
		del,
		getGoods,
		getGoodsById,
		getBrandGoods,
		getGoodsByCode,
		setBrandStatus,
		saveSaleCommission
	};
}

export default {
	install
}