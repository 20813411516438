const install = (Vue, axios) => {
	// 获取商户信息
	let getMerchantInfo = (params = {}) => axios.post('/api/merchant/info', params)

	// 保存商户信息
	let saveMerchantInfo = (params = {}) => axios.post('/api/merchant/information/edit', params)

	// 查询钱包数据
	let getWalletInfo = (params = {}) => axios.post('/api/merchant/wallet/info', params)

	// 获取钱包订单记录
	let getWalletOrderRecord = (params = {}) => axios.post('/api/merchant/wallet/order/record', params)

	// 添加提现账户
	// type	是	int	账号类型 1:微信 2：支付宝 3：银行卡
	// account_number	是	string	账号
	// account_name	是	string	账号姓名
	// subbranch	是	string	支行信息
	// phone	是	string	手机号
	// is_default	是	int	0：否 1：是
	let saveAccount = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/withdrawal/account/edit', params)
		} else {
			return axios.post('/api/merchant/withdrawal/account/add', params)
		}
	}

	/**
	 * 查询账户详情
	 * @param {number} id 
	 */
	let getAccount = (params = {}) => axios.post('/api/merchant/withdrawal/account/info', params)
	/**
	 * 删除账户
	 * @param {number} id 
	 */
	let delAccount = (params = {}) => axios.post('/api/merchant/withdrawal/account/del', params)
	/**
	 * 获取账户列表
	 * @param {number} page 
	 * @param {number} page_size 
	 */
	let getAccounts = (params = {
		page: 1,
		page_size: 999
	}) => axios.post('/api/merchant/withdrawal/account/list', params)

	// 申请提现
	let withdrawal = (params = {}) => axios.post('/api/merchant/wallet/withdrawal/apply', params)

	// 获取提现记录
	let getWithdrawalRecord = (params = {}) => axios.post('/api/merchant/wallet/withdrawal/record', params)
	// 获取账户记录
	let getWalletLog = (params = {}) => axios.post('/api/merchant/wallet/log', params)
	// 设置销售提成
	let saveSaleRatio = (params = {}) => axios.post('/api/merchant/set/sale/ratio', params)
	// 查询销售提成设置
	let getSaleRatio = (params = {}) => {
		return axios.post('/api/merchant/sale/set/info', params).then(res => {
			res.data.set_item.forEach(item => {
				item.sale_ratio = Number(item.sale_ratio)
			})
			return res
		})
	}
	// 查询销售统计
	let getSaleRecords = (params = {}) => axios.post('/api/merchant/sale/record', params)
	
	// 根据字符串获取地图位置
	let geocoder = (params = {}) => axios.post('/api/merchant/map/geocoder', params)
	// 分销配置设置
	let marketingSet = (params = {}) => axios.post('/api/merchant/distribution/set', params)
	// 分销配置获取
	let marketingGet = (params = {}) => axios.post('/api/merchant/distribution/info', params)
	// 获取商家加盟品牌
	let getServeBrandList = (params = {}) => axios.post('/api/getServeBrandList', params)
	
	//根据用户昵称获取用户信息
	let getUserInfoByNick = (params = {}) => axios.post('/api/merchant/withdrawal/getUserInfoByNick', params)
	
	//
	let apply = (params = {}) => axios.post('/api/merchant/wallet/withdrawal/apply', params)
	
	

	


	// 挂载
	Vue.prototype.$api.store = {
		getMerchantInfo,
		saveMerchantInfo,
		getWalletInfo,
		getWalletOrderRecord,
		saveAccount,
		getAccount,
		delAccount,
		getAccounts,
		withdrawal,
		getWithdrawalRecord,
		getWalletLog,
		getSaleRatio,
		saveSaleRatio,
		getSaleRecords,
		geocoder,
		marketingSet,
		marketingGet,
		getServeBrandList,
		getUserInfoByNick,
		apply
	};
}

export default {
	install
}
