// 分类相关接口
const install = (Vue, axios) => {
	// 服务类型
	const SERVICE = Symbol();
	// 商品类型
	const GOODS = Symbol();

	// 删除自营分类
	let del = (type, params = {}) => {
		// type  SERVICE:服务， GOODS:商品
		if (type == SERVICE) {
			return axios.post('/api/merchant/offline/server/classify/del', params)
		} else if (type == GOODS) {
			return axios.post('/api/merchant/offline/goods/classify/del', params)
		}
	};
	// 保存自营分类
	let save = (type, params = {}) => {
		// type  SERVICE:服务， GOODS:商品
		if (type == SERVICE) {
			if (params.id) {
				return axios.post('/api/merchant/offline/server/classify/edit', params)
			} else {
				return axios.post('/api/merchant/offline/server/classify/add', params)
			}
		} else if (type == GOODS) {
			if (params.id) {
				return axios.post('/api/merchant/offline/goods/classify/edit', params)
			} else {
				return axios.post('/api/merchant/offline/goods/classify/add', params)
			}
		}
	};

	// 获取自营分类列表
	let getClassify = (type, params = {}) => {
		// type  SERVICE:服务， GOODS:商品
		if (type == SERVICE) {
			return axios.post('/api/merchant/offline/server/classify/list', params)
		} else if (type == GOODS) {
			return axios.post('/api/merchant/offline/goods/classify/list', params)
		}
	};

	// 获取品牌分类列表
	let getBrandClassify = (type, params = {}) => {
		// type  SERVICE:服务， GOODS:商品
		if (type == SERVICE) {
			// 获取品牌服务分类（父子结构）
			return axios.post('/api/merchant/brand/serve/classify/list', params)
		} else if (type == GOODS) {
			// 获取品牌商品分类（父子结构）
			return axios.post('/api/merchant/brand/product/classify/list', params)
		}
	};

	// 设置品牌项目分类上下架
	let setBrandClassifyShow = (params = {}) => {
		return axios.post('/api/merchant/brand/service/status/edit', params)
	}

	// 获取所有分类
	let getAllClassify = (params) => axios.post('/api/merchant/classify/list', params)

	// 挂载
	Vue.prototype.$api.classify = {
		SERVICE,
		GOODS,
		del,
		save,
		getClassify,
		getBrandClassify,
		setBrandClassifyShow,
		getAllClassify
	};
}

export default {
	install
}
