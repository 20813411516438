// 用户相关接口
const install = (Vue, axios) => {

	// 获取待处理数量
	let getPendingCount = (params = {}) => axios.post('/api/merchant/pending/order', params);
	// 服务统计
	let serve = (params = {}) => axios.post('/api/merchant/serve/order/statistic', params);
	// 订单统计
	let order = (params = {}) => axios.post('/api/merchant/order/statistic', params);
	// 会员统计
	let member = (params = {}) => axios.post('/api/merchant/member/statistic', params);
	// 历史统计
	let history = (params = {}) => axios.post('/api/merchant/history/statistic', params);
	// 会员生日提醒
	let getBirthday = (params = {}) => axios.post('/api/merchant/member/birthday', params);
	// 品牌库存预警
	let pWarning = (params = {}) => axios.post('/api/merchant/stoke/warning/list', params);
	// 自营库存预警
	let zWrning = (params = {}) => axios.post('/api/merchant/offline/stoke/warning/list', params);
	// 销售排行
	let saleRanking = (params = {}) => axios.post('/api/merchant/get/sale/ranking', params);
	// 技师排行
	let technicianRanking = (params = {}) => axios.post('/api/merchant/get/technician/ranking', params);
	// 会员消费排行
	let memberRanking = (params = {}) => axios.post('/api/merchant/get/member/ranking', params);
	// 品牌销售排行数据
	let downloadOne = (params = {}) => axios.post('/api/merchant/export/sale/ranking', params,{responseType: 'arraybuffer'});
	// 自营销售排行数据
	let downloadTwo = (params = {}) => axios.post('/api/merchant/export/sale/ranking', params,{responseType: 'arraybuffer'});
	// 技师排行数据
	let downloadThree = (params = {}) => axios.post('/api/merchant/export/technician/ranking', params,{responseType: 'arraybuffer'});
	// 会员消费排行数据
	let downloadFour = (params = {}) => axios.post('/api/merchant/export/member/ranking', params,{responseType: 'arraybuffer'});
	// 概况统计
	let surveyRanking = (params = {}) => axios.post('/api/merchant/get/statistics', params);
	//导出概况统计
	let downloadSurvey = (params = {}) => axios.post('/api/merchant/export/Statistics', params,{responseType: 'arraybuffer'});
	// 销售统计
	let saleRecordRanking = (params = {}) => axios.post('/api/merchant/technician/saleRecord', params);
	// 服务统计
	let severRecordRanking = (params = {}) => axios.post('/api/merchant/technician/severRecord', params);
	//导出服务统计
	let exportSeverRecord = (params = {}) => axios.post('/api/merchant/technician/exportSeverRecord', params,{responseType: 'arraybuffer'});
	//导出销售统计
	let exportSaleRecord = (params = {}) => axios.post('/api/merchant/technician/exportSaleRecord', params,{responseType: 'arraybuffer'});
	// 挂载
	Vue.prototype.$api.statistics = {
		getPendingCount,
		serve,
		order,
		member,
		history,
		getBirthday,
		pWarning,
		zWrning,
		saleRanking,
		technicianRanking,
		memberRanking,
		downloadOne,
		downloadTwo,
		downloadThree,
		downloadFour,
		surveyRanking,
		downloadSurvey,
		saleRecordRanking,
		severRecordRanking,
		exportSeverRecord,
		exportSaleRecord
	};
}

export default {
	install
}
