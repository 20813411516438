const install = (Vue, axios) => {
 
     //获取护理记录列表
	let getCustomerCareList = (params = {}) => axios.post('/api/merchant/table/nursing/list', params)
	
	//护理记录导出
	let exportCustomerCareList=(params = {}) => axios.post('/api/merchant/table/nursing/export', params,{responseType: 'arraybuffer'});
	
	//删除护理记录
	let delCustomerCareList = (params = {}) => axios.post('/api/merchant/table/nursing/del', params)
	
	
	
	//新增
	let addCustomerCareList = (params = {}) => axios.post('/api/merchant/table/nursing/add', params)
    //编辑
	let editCustomerCareList = (params = {}) => axios.post('/api/merchant/table/nursing/edit', params)
	//详情
	let getInfo = (params = {}) => axios.post('/api/merchant/table/nursing/info', params)
	
	//产品记录
	let getProductSellList = (params = {}) => axios.post('/api/merchant/tablecl/productSellList', params)
	
	//产品记录导出
	let exportProductSellList=(params = {}) => axios.post('/api/merchant/tablecl/productSellExport', params,{responseType: 'arraybuffer'});
	
	//充值记录
	let getRechargeList = (params = {}) => axios.post('/api/merchant/tablecl/rechargeList', params)
	
	//充值记录导出
	let exportRechargeExport=(params = {}) => axios.post('/api/merchant/tablecl/rechargeExport', params,{responseType: 'arraybuffer'});
	
	//客户意见列表
	let getCustomerConsultingList=(params = {}) => axios.post('/api/merchant/tablecl/customerConsultingList', params)
	
	//客户意见详情
	let getCustomerConsultingInfo=(params = {}) => axios.post('/api/merchant/tablecl/customerConsultingInfo', params)
	
	//客户咨询项目列表
	let getcustomerConsultingItem=(params = {}) => axios.post('/api/merchant/tablecl/customerConsultingItem', params)
	
	//添加 客户意见表
	
	let customerConsultingAdd=(params = {}) => axios.post('/api/merchant/tablecl/customerConsultingAdd', params)
	
    //删除 客户意见
	let customerConsultingDel=(params = {}) => axios.post('/api/merchant/tablecl/customerConsultingDel', params)
	
	//调查问卷列表
	let customerQuestionnaireList=(params = {}) => axios.post('/api/merchant/tablecl/customerQuestionnaireList', params)
	
	//删除调查问卷
	let customerQuestionnaireDel=(params = {}) => axios.post('/api/merchant/tablecl/customerQuestionnaireDel', params)
	
	//调查问卷详情
	let customerQuestionnaireInfo=(params = {}) => axios.post('/api/merchant/tablecl/customerQuestionnaireInfo', params)
	
	//新增调查问卷
	let customerQuestionnaireAdd=(params = {}) => axios.post('/api/merchant/tablecl/customerQuestionnaireAdd', params)
	
	
	
	
	
	
	
	
	// 挂载
	Vue.prototype.$api.tablewx = {
		getCustomerCareList,
		addCustomerCareList,
		editCustomerCareList,
		exportCustomerCareList,
		getInfo,
		getProductSellList,
		exportProductSellList,
		getRechargeList,
		exportRechargeExport,
		delCustomerCareList,
		getCustomerConsultingList,
		getCustomerConsultingInfo,
		getcustomerConsultingItem,
		customerConsultingAdd,
		customerConsultingDel,
		customerQuestionnaireList,
		customerQuestionnaireDel,
		customerQuestionnaireInfo,
		customerQuestionnaireAdd
	};
}

export default {
	install
}
