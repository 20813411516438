// 项目相关接口
const install = (Vue, axios) => {


	// 保存
	let save = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/technician/edit', params)
		} else {
			return axios.post('/api/merchant/technician/add', params)
		}
	};
	// 获取列表
	let getStaffs = (params = {}) => axios.post('/api/merchant/technician/list', params)
	// 获取单条
	let getStaff = (params = {}) => axios.post('/api/merchant/technician/info', params)
	// 删除列表
	let del = (params = {}) => axios.post('/api/merchant/technician/del', params)
	
	// 技师记录
	let getRecords = (params = {}) => axios.post('/api/merchant/technician/record', params)
	

	// 挂载
	Vue.prototype.$api.staff = {
		save,
		del,
		getStaffs,
		getStaff,
		getRecords
	};
}

export default {
	install
}
