import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import './assets/common.css';
// 富文本--------------开始
//引用富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
//引入富文本css
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.config.productionTip = false
//富文本编辑器添加实例
Vue.use(VueQuillEditor, /* { default global options } */ )
// 富文本--------------结束

// 复制文本功能
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Vue.use(ElementUI, { size: 'mini' })
Vue.use(ElementUI)

// VueRouter
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
console.log('process.env.NODE_ENV：' + process.env.NODE_ENV)

// 引入uView提供的对vuex的简写法文件
import store from './store'
import vuexStore from './store/$u.mixin'
Vue.mixin(vuexStore);


// http接口API抽离，免于写url或者一些固定的参数
import httpApi from './services/api.index'
Vue.use(httpApi, axios)

const vm = new Vue({
  router,
  store,
  render: h => h(App)
})

// 环境判断
if (process.env.NODE_ENV === 'development') {
	// 么么侠开发环境
	axios.defaults.baseURL = "http://192.168.52.1";
	axios.defaults.baseURL = "http://192.168.1.26";
	axios.defaults.baseURL = "http://192.168.1.68";
	// axios.defaults.baseURL = "https://momenapidev.memexia.com";
    axios.defaults.baseURL = "https://momenapi.memexia.com";
	// 设置项目名称
	vm.$vuex('vuex_project', 'MOMEN么么侠')
	document.title = 'MOMEN么么侠-云后台收银系统'
} else
	if (process.env.NODE_ENV === 'test') {
	// 么么侠测试环境
	axios.defaults.baseURL = "http://192.168.1.181";
	axios.defaults.baseURL = "https://momenapidev.memexia.com";
	// 设置项目名称
	vm.$vuex('vuex_project', 'MOMEN么么侠')
	document.title = 'MOMEN么么侠-云后台收银系统'
} else if (process.env.NODE_ENV === 'production') {
	// 么么侠生产环境
	axios.defaults.baseURL = "https://momenapi.memexia.com";
	// 设置项目名称
	vm.$vuex('vuex_project', 'MOMEN么么侠')
	document.title = 'MOMEN么么侠-云后台收银系统'
}
//  else if (process.env.NODE_ENV === 'development-ls') {
// 	// 雷氏普爱开发环境
// 	axios.defaults.baseURL = "https://api.leishihk.com";
// 	// 设置项目名称
// 	vm.$vuex('vuex_project', '雷氏普爱')
// 	document.title = '雷氏普爱-云后台收银系统'
// } else if (process.env.NODE_ENV === 'production-ls') {
// 	// 雷氏普爱生产环境
// 	axios.defaults.baseURL = "https://api.leishihk.com";
// 	// 设置项目名称
// 	vm.$vuex('vuex_project', '雷氏普爱')
// 	document.title = '雷氏普爱-云后台收银系统'
// }


// http拦截器
import httpInterceptor from './common/http.interceptor'
Vue.use(httpInterceptor, vm)

// 挂载工具
import util from './common/util'
Vue.use(util, vm)

// 挂载日期工具，需要卸载util后面
import dateUtil from './common/util.date'
Vue.use(dateUtil)

/**
 * 权限查询函数
 */
Vue.prototype.$allow = function(id) {
	return vm.vuex_user && vm.vuex_user.permissions && vm.vuex_user.permissions.indexOf(id) >= 0;
}

// 自定义全局过滤器
import myFilter from './common/common.filter.js'
Vue.use(myFilter, vm)

vm.$mount('#app')
