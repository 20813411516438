import Vue from 'vue';
import VueRouter from 'vue-router';

// 解决点击当前路由（跳转到自己）控制台报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
    // routes 中配置的路由会被渲染在 App.uve 的 <router-view> 中
    {
        path: '/',
        name: '首页',
        component: () => import('../views/Index.vue'),
        // redirect: '/home',
        children: [
            // children 中配置的路由会被渲染在 Home 的 <router-view> 中
            // ----开单
            {
                name: '开单',
                path: '/createOrder',
                component: () => import('../views/order/CreateOrder.vue'),
                // 关于meta的说明，如果不写meta和breadcrumb，则首页面包屑默认取$route.matched
                // 如果写了，则根据breadcrumb来生成面包屑
                // 相同页面不同名称的判断，参考Index.vue中的代码，根据指定的param来判断
                // 如果该页面为首页下的一级页面，则可以不写mate，取默认设置就好
                meta: {
                    // active 来指定首页左侧menu高亮显示，不写则默认path第一级
                    active: '/createOrder',
                    breadcrumb: [
                        {
                            name: '开单',
                        },
                    ],
                },
            },
            // ----订单
            {
                name: '订单',
                path: '/order',
                component: () => import('../views/order/Home.vue'),
            },
            {
                name: '结单',
                path: '/order/statement/:id?',
                component: () => import('../views/order/Statement.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/order',
                            name: '订单',
                        },
                        {
                            name: '结单',
                        },
                    ],
                },
            },
            // ----顾客取货
            {
                name: '取货',
                path: '/pickup',
                component: () => import('../views/pickup/Home.vue'),
            },
            {
                name: '取件码取件',
                path: '/pickup/statement/:id?',
                component: () => import('../views/pickup/Statement.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/pickup',
                            name: '取货',
                        },
                        {
                            name: '取件码取件',
                        },
                    ],
                },
            },
            // ----套餐管理
            {
                // 套餐列表
                name: '套餐列表',
                path: '/group',
                component: () => import('../views/group/Home.vue'),
                meta: {
                    breadcrumb: [
                        {
                            name: '套餐列表',
                        },
                    ],
                },
            },
            {
                // 编辑套餐
                name: '编辑套餐',
                path: '/group/edit/:id?',
                component: () => import('../views/group/Edit.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/group',
                            name: '套餐列表',
                        },
                        {
                            name: '新增套餐',
                            name2: '编辑套餐',
                            param: 'id',
                        },
                    ],
                },
            },
            // ----套餐规格管理
            {
                // 套餐规格列表
                name: '规格列表',
                path: '/groupItem',
                component: () => import('../views/groupItem/Home.vue'),
                meta: {
                    breadcrumb: [
                        {
                            name: '规格列表',
                        },
                    ],
                },
            },
            {
                // 编辑套餐规格
                name: '编辑规格',
                path: '/groupItem/edit/:id?',
                component: () => import('../views/groupItem/Edit.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/groupItem',
                            name: '规格列表',
                        },
                        {
                            name: '新增规格',
                            name2: '编辑规格',
                            param: 'id',
                        },
                    ],
                },
            },
            // ----项目管理
            {
                // 项目列表
                name: '项目列表',
                path: '/project',
                component: () => import('../views/project/Home.vue'),
                meta: {
                    breadcrumb: [
                        {
                            name: '项目列表',
                        },
                    ],
                },
            },
            {
                // 编辑项目
                name: '编辑项目',
                path: '/project/edit/:id?',
                component: () => import('../views/project/Edit.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/project',
                            name: '项目列表',
                        },
                        {
                            name: '新增项目',
                            name2: '编辑项目',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 分类管理
                name: '项目分类管理',
                path: '/projectClassify/:type',
                component: () => import('../views/classify/Home'),
                meta: {
                    active: '/projectClassify/project',
                    breadcrumb: [
                        {
                            name: '分类管理',
                        },
                    ],
                },
            },
            // ----商品管理
            {
                // 商品列表
                name: '商品列表',
                path: '/goods',
                component: () => import('../views/goods/Home.vue'),
                meta: {
                    breadcrumb: [
                        {
                            name: '商品列表',
                        },
                    ],
                },
            },
            {
                // 编辑商品
                name: '编辑商品',
                path: '/goods/edit/:id?',
                component: () => import('../views/goods/Edit.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/goods',
                            name: '商品列表',
                        },
                        {
                            name: '新增商品',
                            name2: '编辑商品',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 分类管理
                name: '商品分类管理',
                path: '/goodsClassify/:type',
                component: () => import('../views/classify/Home'),
                meta: {
                    // active 来指定首页左侧menu高亮显示，不写则默认path第一级
                    active: '/goodsClassify/goods',
                    breadcrumb: [
                        {
                            name: '分类管理',
                        },
                    ],
                },
            },
            // 技师管理
            {
                // 技师列表
                name: '技师管理',
                path: '/staff',
                component: () => import('../views/staff/Home'),
            },
            {
                // 编辑技师
                name: '编辑技师',
                path: '/staff/edit/:id?',
                component: () => import('../views/staff/Edit'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/staff',
                            name: '技师管理',
                        },
                        {
                            name: '新增技师',
                            name2: '编辑技师',
                            param: 'id',
                        },
                    ],
                },
            },
            // 库存管理
            {
                // 库存列表
                name: '库存管理',
                path: '/stock',
                component: () => import('../views/stock/Home'),
            },
            {
                // 出入库明细
                name: '出入库明细',
                path: '/stock/detail',
                component: () => import('../views/stock/Detail'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/stock',
                            name: '库存管理',
                        },
                        {
                            name: '出入库记录',
                        },
                    ],
                },
            },
            {
                // 会员打卡
                name: '采购订单',
                path: '/memberRecharge',
                component: () => import('../views/memberRecharge/Home'),
            },
            {
                // 收益统计
                name: '收益统计',
                path: '/memberRecharge/incomeStatistics',
                component: () => import('../views/memberRecharge/incomeStatistics'),
            },

            // View records
            // 收益记录查看
            {
                //记录查看
                name: '记录查看',
                path: '/memberRecharge/viewRecords/:id',
                component: () => import('../views/memberRecharge/viewRecords'),
            },

            {
                //用户购买详情
                name: '用户购买详情',
                path: '/memberRecharge/user/:id',
                component: () => import('../views/memberRecharge/User'),
            },

            {
                // 会员打卡
                name: '会员卡记录',
                path: '/clockOn',
                component: () => import('../views/clockOn/Home'),
            },
            {
                // 卡项详情
                name: '卡项详情',
                path: '/clockOn/details/:id',
                component: () => import('../views/clockOn/Details'),
            },
            {
                // 查看兑换码
                name: '查看兑换码',
                path: '/clockOn/lookCode/:id',
                component: () => import('../views/clockOn/lookCode'),
            },
            {
                // 打卡记录
                name: '打卡记录',
                path: '/clockOn/details/:userId/record/:actId',
                component: () => import('../views/clockOn/Record'),
            },
            {
                // 卡项详情
                name: '卡项详情',
                path: '/clockOn/user/:id',
                component: () => import('../views/clockOn/User'),
            },
            // 会员管理
            {
                // 会员列表
                name: '会员管理',
                path: '/member',
                component: () => import('../views/member/Home'),
            },
            {
                // 编辑会员
                name: '编辑会员',
                path: '/member/edit/:id?',
                component: () => import('../views/member/Edit'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/member',
                            name: '会员管理',
                        },
                        {
                            name: '新增会员',
                            name2: '编辑会员',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 会员详情
                name: '会员详情',
                path: '/member/detail/:id',
                component: () => import('../views/member/Detail'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/member',
                            name: '会员管理',
                        },
                        {
                            name: '会员详情',
                        },
                    ],
                },
            },
            {
                // 会员的会员卡
                name: '会员卡',
                path: '/member/cards/:memberId',
                component: () => import('../views/member/Cards'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/member',
                            name: '会员管理',
                        },
                        {
                            name: '会员卡',
                        },
                    ],
                },
            },
            {
                // 会员的套餐
                name: '套餐',
                path: '/member/groups/:memberId',
                component: () => import('../views/member/Groups'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/member',
                            name: '会员管理',
                        },
                        {
                            name: '套餐',
                        },
                    ],
                },
            },
            {
                // 会员的账户明细
                name: '账户明细',
                path: '/member/account/:memberId',
                component: () => import('../views/member/Account'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/member',
                            name: '会员管理',
                        },
                        {
                            name: '账户明细',
                        },
                    ],
                },
            },
            {
                // 余额变更记录
                name: '余额变更记录',
                path: '/member/balanceLog/:memberId',
                component: () => import('../views/member/BalanceLog'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/member',
                            name: '会员管理',
                        },
                        {
                            name: '余额变更记录',
                        },
                    ],
                },
            },
            // 会员档案
            {
                name: '会员档案',
                path: '/document',
                component: () => import('../views/document/Home'),
            },
            // 卡项相关路由，充值卡、服务卡
            {
                // 服务卡
                name: '服务卡',
                path: '/card/serve',
                component: () => import('../views/card/serve/Home.vue'),
                meta: {
                    active: '/card/serve',
                    breadcrumb: [
                        {
                            name: '服务卡',
                        },
                    ],
                },
            },
            {
                // 编辑服务卡
                name: '编辑服务卡',
                path: '/card/serve/edit/:id?',
                component: () => import('../views/card/serve/Edit.vue'),
                meta: {
                    active: '/card/serve',
                    breadcrumb: [
                        {
                            name: '服务卡',
                            path: '/card/serve',
                        },
                        {
                            name: '新增服务卡',
                            name2: '编辑服务卡',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 充值卡
                name: '充值卡',
                path: '/card/recharge',
                component: () => import('../views/card/recharge/Home.vue'),
                meta: {
                    active: '/card/recharge',
                    breadcrumb: [
                        {
                            name: '充值卡',
                        },
                    ],
                },
            },
            {
                // 编辑充值卡
                name: '编辑充值卡',
                path: '/card/recharge/edit/:id?',
                component: () => import('../views/card/recharge/Edit.vue'),
                meta: {
                    active: '/card/recharge',
                    breadcrumb: [
                        {
                            name: '充值卡',
                            path: '/card/recharge',
                        },
                        {
                            name: '新增充值卡',
                            name2: '编辑充值卡',
                            param: 'id',
                        },
                    ],
                },
            },
            // 营销推广
            {
                // 营销推广
                name: '营销推广',
                path: '/marketing',
                component: () => import('../views/marketing/Home.vue'),
            },
            // 商户设置
            {
                // 商户设置
                name: '商户设置',
                path: '/store',
                component: () => import('../views/store/Setting.vue'),
            },
            // 账户
            {
                // 账户
                name: '账户',
                path: '/account',
                component: () => import('../views/store/Account.vue'),
            },
            {
                // 提现
                name: '提现',
                path: '/account/withdrawal',
                component: () => import('../views/store/Withdrawal.vue'),
                meta: {
                    active: '/account',
                    breadcrumb: [
                        {
                            name: '账户',
                            path: '/account',
                        },
                        {
                            name: '提现',
                        },
                    ],
                },
            },
            {
                // 提现账户
                name: '提现账户',
                path: '/account/bankcard',
                component: () => import('../views/store/bankcard/Home.vue'),
                meta: {
                    active: '/account',
                    breadcrumb: [
                        {
                            name: '账户',
                            path: '/account',
                        },
                        {
                            path: '/account/withdrawal',
                            name: '提现',
                        },
                        {
                            name: '提现账户',
                        },
                    ],
                },
            },
            {
                // 编辑提现账户
                name: '编辑提现账户',
                path: '/account/bankcard/edit/:id?',
                component: () => import('../views/store/bankcard/Edit.vue'),
                meta: {
                    active: '/account',
                    breadcrumb: [
                        {
                            name: '账户',
                            path: '/account',
                        },
                        {
                            path: '/account/withdrawal',
                            name: '提现',
                        },
                        {
                            path: '/account/bankcard',
                            name: '提现账户',
                        },
                        {
                            name: '新增提现账户',
                            name2: '编辑提现账户',
                            param: 'id',
                        },
                    ],
                },
            },
            // 系统设置
            {
                // 用户列表
                name: '用户列表',
                path: '/systemUser',
                component: () => import('../views/system/user/Home.vue'),
            },
            // 统计中心
            {
                name: '统计中心',
                path: '/statistics/home',
                component: () => import('../views/statistics/Home.vue'),
            },
            // 概况统计
            {
                name: '概况统计',
                path: '/statistics/survey',
                component: () => import('../views/statistics/survey.vue'),
            },
            // 技师统计
            {
                name: '技师统计',
                path: '/statistics/artificer',
                component: () => import('../views/statistics/artificer.vue'),
            },
            {
                // 编辑用户
                name: '编辑用户',
                path: '/systemUser/edit/:id?',
                component: () => import('../views/system/user/Edit.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/systemUser',
                            name: '用户列表',
                        },
                        {
                            name: '新增用户',
                            name2: '编辑用户',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 角色列表
                name: '角色列表',
                path: '/systemRole',
                component: () => import('../views/system/role/Home.vue'),
            },
            {
                // 编辑角色
                name: '编辑角色',
                path: '/systemRole/edit/:id?',
                component: () => import('../views/system/role/Edit.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/project',
                            name: '角色列表',
                        },
                        {
                            name: '新增角色',
                            name2: '编辑角色',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 权限列表
                name: '权限列表',
                path: '/systemPower',
                component: () => import('../views/system/power/Home.vue'),
            },
            {
                // 编辑权限
                name: '编辑权限',
                path: '/systemPower/edit/:id?',
                component: () => import('../views/system/power/Edit.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/systemPower',
                            name: '权限列表',
                        },
                        {
                            name: '新增权限',
                            name2: '编辑权限',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 打印设置
                name: '打印设置',
                path: '/print',
                component: () => import('../views/system/Print.vue'),
            },
            {
                // 帮助中心
                name: '帮助中心',
                path: '/helps',
                component: () => import('../views/system/Helps.vue'),
            },
            {
                // 员工提成表
                name: '员工提成表',
                path: '/salary',
                component: () => import('../views/salary/Home.vue'),
                meta: {
                    active: '/salary',
                    breadcrumb: [
                        {
                            name: '员工提成表',
                        },
                    ],
                },
            },
            {
                // 提成设置
                name: '提成设置',
                path: '/salary/setting',
                component: () => import('../views/salary/Setting.vue'),
                meta: {
                    active: '/salary/setting',
                    breadcrumb: [
                        {
                            name: '提成设置',
                        },
                    ],
                },
            },
            // 表格中心
            {
                // 会员资料
                name: '会员资料',
                path: '/tables/member',
                component: () => import('../views/tables/member/Home.vue'),
                meta: {
                    active: '/tables/member',
                    breadcrumb: [
                        {
                            name: '会员资料',
                        },
                    ],
                },
            },
            {
                // 客户档案
                name: '客户档案',
                path: '/tables/customer',
                component: () => import('../views/tables/customer/Home.vue'),
                meta: {
                    active: '/tables/customer',
                    breadcrumb: [
                        {
                            name: '客户档案',
                        },
                    ],
                },
            },
            {
                // 编辑客户档案
                name: '编辑客户档案',
                path: '/tables/customer/edit/:id?',
                component: () => import('../views/tables/customer/Edit.vue'),
                meta: {
                    active: '/tables/customer',
                    breadcrumb: [
                        {
                            name: '编辑客户档案',
                        },
                    ],
                },
            },
            {
                // 产品领用表
                name: '产品领用表',
                path: '/tables/receive',
                component: () => import('../views/tables/receive/Home.vue'),
                meta: {
                    active: '/tables/receive',
                    breadcrumb: [
                        {
                            name: '产品领用表',
                        },
                    ],
                },
            },
            {
                // 编辑产品领用记录
                name: '编辑产品领用记录',
                path: '/tables/receive/edit/:id?',
                component: () => import('../views/tables/receive/Edit.vue'),
                meta: {
                    active: '/tables/receive',
                    breadcrumb: [
                        {
                            name: '编辑产品领用记录',
                        },
                    ],
                },
            },
            {
                // 库存月报表
                name: '库存月报表',
                path: '/tables/stock',
                component: () => import('../views/tables/stock/Home.vue'),
                meta: {
                    active: '/tables/stock',
                    breadcrumb: [
                        {
                            name: '库存月报表',
                        },
                    ],
                },
            },
            {
                // 促销活动总结表
                name: '促销活动总结表',
                path: '/tables/activitySummary',
                component: () => import('../views/tables/activitySummary/Home.vue'),
                meta: {
                    active: '/tables/activitySummary',
                    breadcrumb: [
                        {
                            name: '促销活动总结表',
                        },
                    ],
                },
            },
            {
                // 编辑促销活动总结
                name: '编辑促销活动总结',
                path: '/tables/activitySummary/edit/:id?',
                component: () => import('../views/tables/activitySummary/Edit.vue'),
                meta: {
                    active: '/tables/activitySummary',
                    breadcrumb: [
                        {
                            name: '编辑促销活动总结',
                        },
                    ],
                },
            },
            {
                // 新客咨询
                name: '新客咨询',
                path: '/tables/newGuest',
                component: () => import('../views/tables/newGuest/Index.vue'),
                meta: {
                    active: '/tables/newGuest',
                    breadcrumb: [
                        {
                            name: '新客咨询',
                        },
                    ],
                },
            },
            {
                // 编辑会员资料
                name: '编辑会员资料',
                path: '/tables/member/edit/:id',
                component: () => import('../views/tables/member/Edit.vue'),
                meta: {
                    active: '/tables/member',
                    breadcrumb: [
                        {
                            name: '编辑会员资料',
                        },
                    ],
                },
            },
            {
                // 编辑会员资料
                name: '查看会员资料',
                path: '/tables/member/detail/:id',
                component: () => import('../views/tables/member/Detail.vue'),
                meta: {
                    active: '/tables/member',
                    breadcrumb: [
                        {
                            name: '查看会员资料',
                        },
                    ],
                },
            },
            {
                // 新客咨询
                name: '新客咨询',
                path: '/tables/newGuest',
                component: () => import('../views/tables/newGuest/Index.vue'),
                meta: {
                    active: '/tables/newGuest',
                    breadcrumb: [
                        {
                            name: '新客咨询',
                        },
                    ],
                },
            },
            {
                // 编辑新客咨询
                name: '编辑新客咨询',
                path: '/tables/newGuest/edit/:id?',
                component: () => import('../views/tables/newGuest/Edit.vue'),
                meta: {
                    active: '/tables/newGuest',
                    breadcrumb: [
                        {
                            name: '新客咨询',
                            path: '/tables/newGuest',
                        },
                        {
                            name: '新增新客咨询',
                            name2: '编辑新客咨询',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 客户预约
                name: '客户预约',
                path: '/tables/reserveRecord',
                component: () => import('../views/tables/reserveRecord/Index.vue'),
                meta: {
                    active: '/tables/reserveRecord',
                    breadcrumb: [
                        {
                            name: '客户预约',
                        },
                    ],
                },
            },
            {
                // 编辑客户预约
                name: '编辑客户预约',
                path: '/tables/reserveRecord/edit/:id?',
                component: () => import('../views/tables/reserveRecord/Edit.vue'),
                meta: {
                    active: '/tables/reserveRecord',
                    breadcrumb: [
                        {
                            name: '客户预约',
                            path: '/tables/reserveRecord',
                        },
                        {
                            name: '新增客户预约',
                            name2: '编辑客户预约',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 财务报表
                name: '财务报表',
                path: '/tables/finance',
                component: () => import('../views/tables/finance/Index.vue'),
                meta: {
                    active: '/tables/finance',
                    breadcrumb: [
                        {
                            name: '财务报表',
                        },
                    ],
                },
            },
            {
                // 编辑财务报表
                name: '编辑财务报表',
                path: '/tables/finance/edit/:id?',
                component: () => import('../views/tables/finance/Edit.vue'),
                meta: {
                    active: '/tables/finance',
                    breadcrumb: [
                        {
                            name: '财务报表',
                            path: '/tables/finance',
                        },
                        {
                            name: '新增财务报表',
                            name2: '编辑财务报表',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                name: '财务报表详情',
                path: '/tables/finance/details/:id?',
                component: () => import('../views/tables/finance/Details.vue'),
                meta: {
                    active: '/tables/finance',
                    breadcrumb: [
                        {
                            name: '财务报表',
                        },
                    ],
                },
            },
            {
                // 人员工资
                name: '人员工资',
                path: '/tables/salary',
                component: () => import('../views/tables/salary/Index.vue'),
                meta: {
                    active: '/tables/salary',
                    breadcrumb: [
                        {
                            name: '人员工资',
                        },
                    ],
                },
            },
            {
                // 编辑人员工资
                name: '编辑人员工资',
                path: '/tables/salary/edit/:id?',
                component: () => import('../views/tables/salary/Edit.vue'),
                meta: {
                    active: '/tables/salary',
                    breadcrumb: [
                        {
                            name: '人员工资',
                            path: '/tables/salary',
                        },
                        {
                            name: '新增人员工资',
                            name2: '编辑人员工资',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                name: '人员工资详情',
                path: '/tables/salary/details/:id?',
                component: () => import('../views/tables/salary/Details.vue'),
                meta: {
                    active: '/tables/salary',
                    breadcrumb: [
                        {
                            name: '人员工资',
                        },
                    ],
                },
            },
            {
                // 现金报表
                name: '现金报表',
                path: '/tables/cashStatement',
                component: () => import('../views/tables/cashStatement/Index.vue'),
                meta: {
                    active: '/tables/cashStatement',
                    breadcrumb: [
                        {
                            name: '现金报表',
                        },
                    ],
                },
            },
            {
                // 编辑现金报表
                name: '编辑现金报表',
                path: '/tables/cashStatement/edit/:id?',
                component: () => import('../views/tables/cashStatement/Edit.vue'),
                meta: {
                    active: '/tables/cashStatement',
                    breadcrumb: [
                        {
                            name: '现金报表',
                            path: '/tables/cashStatement',
                        },
                        {
                            name: '新增现金报表',
                            name2: '编辑现金报表',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                name: '现金报表详情',
                path: '/tables/cashStatement/details/:id?',
                component: () => import('../views/tables/cashStatement/Details.vue'),
                meta: {
                    active: '/tables/cashStatement',
                    breadcrumb: [
                        {
                            name: '现金报表',
                        },
                    ],
                },
            },
            {
                // 营业报表
                name: '营业报表',
                path: '/tables/business',
                component: () => import('../views/tables/business/Index.vue'),
                meta: {
                    active: '/tables/business',
                    breadcrumb: [
                        {
                            name: '营业报表',
                        },
                    ],
                },
            },
            {
                // 编辑营业报表
                name: '编辑营业报表',
                path: '/tables/business/edit/:id?',
                component: () => import('../views/tables/business/Edit.vue'),
                meta: {
                    active: '/tables/business',
                    breadcrumb: [
                        {
                            name: '营业报表',
                            path: '/tables/business',
                        },
                        {
                            name: '新增营业报表',
                            name2: '编辑营业报表',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                name: '营业报表详情',
                path: '/tables/business/details/:id?',
                component: () => import('../views/tables/business/Details.vue'),
                meta: {
                    active: '/tables/business',
                    breadcrumb: [
                        {
                            name: '营业报表',
                        },
                    ],
                },
            },
            {
                // 月份工资核算表
                name: '月份工资核算表',
                path: '/tables/payroll',
                component: () => import('../views/tables/payroll/Index.vue'),
                meta: {
                    active: '/tables/payroll',
                    breadcrumb: [
                        {
                            name: '月份工资核算表',
                        },
                    ],
                },
            },
            {
                // 编辑月份工资核算表
                name: '编辑月份工资核算表',
                path: '/tables/payroll/edit/:id?',
                component: () => import('../views/tables/payroll/Edit.vue'),
                meta: {
                    active: '/tables/payroll',
                    breadcrumb: [
                        {
                            name: '月份工资核算表',
                            path: '/tables/payroll',
                        },
                        {
                            name: '新增月份工资核算表',
                            name2: '编辑月份工资核算表',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                name: '月份工资核算表详情',
                path: '/tables/payroll/details/:id?',
                component: () => import('../views/tables/payroll/Details.vue'),
                meta: {
                    active: '/tables/payroll',
                    breadcrumb: [
                        {
                            name: '月份工资核算表',
                        },
                    ],
                },
            },
            {
                //客户护理记录
                name: '客户护理记录',
                path: '/tables/customerCare',
                component: () => import('../views/tables/customerCare/Index.vue'),
                meta: {
                    active: '/tables/customerCare',
                    breadcrumb: [
                        {
                            name: '客户护理记录',
                        },
                    ],
                },
            },
            {
                name: '客户护理记录',
                path: '/tables/customerCare/edit/:id?',
                component: () => import('../views/tables/customerCare/Edit.vue'),
                meta: {
                    active: '/tables/customerCare',
                    breadcrumb: [
                        {
                            name: '客户护理记录',
                            path: '/tables/customerCare',
                        },
                        {
                            name: '新增客户护理记录',
                            name2: '编辑客户护理记录',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                //购买产品
                name: '购买产品记录',
                path: '/tables/buyProducts',

                component: () => import('../views/tables/buyProducts/Index.vue'),
                meta: {
                    active: '/tables/buyProducts',
                    breadcrumb: [
                        {
                            name: '购买产品记录',
                        },
                    ],
                },
            },
            {
                //充值记录
                name: '充值记录',
                path: '/tables/rechargeRecordForm',

                component: () => import('../views/tables/rechargeRecordForm/Index.vue'),
                meta: {
                    active: '/tables/rechargeRecordForm',
                    breadcrumb: [
                        {
                            name: '充值记录',
                        },
                    ],
                },
            },
            {
                //顾客意见咨询
                name: '顾客意见咨询',
                path: '/tables/customerOpinion',

                component: () => import('../views/tables/customerOpinion/Index.vue'),
                meta: {
                    active: '/tables/customerOpinion',
                    breadcrumb: [
                        {
                            name: '顾客意见咨询',
                        },
                    ],
                },
            },
            {
                name: '顾客意见咨询',
                path: '/tables/customerOpinion/edit/:id?',
                component: () => import('../views/tables/customerOpinion/Edit.vue'),
                meta: {
                    active: '/tables/customerOpinion',
                    breadcrumb: [
                        {
                            name: '顾客意见咨询',
                            path: '/tables/customerOpinion',
                        },
                        {
                            name: '新增顾客意见咨询',
                            name2: '编辑顾客意见咨询',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 顾客意见咨询详情
                name: '顾客意见咨询详情',
                path: '/tables/customerOpinion/detail/:id',
                component: () => import('../views/tables/customerOpinion/Detail.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/tables/customerOpinion',
                            name: '顾客意见咨询',
                        },
                        {
                            name: '顾客意见咨询详情',
                        },
                    ],
                },
            },
            {
                //顾客调查问卷
                name: '顾客调查问卷',
                path: '/tables/customerQuestionnaire',

                component: () => import('../views/tables/customerQuestionnaire/Index.vue'),
                meta: {
                    active: '/tables/customerQuestionnaire',
                    breadcrumb: [
                        {
                            name: '顾客调查问卷',
                        },
                    ],
                },
            },
            {
                name: '顾客调查问卷',
                path: '/tables/customerQuestionnaire/edit/:id?',
                component: () => import('../views/tables/customerQuestionnaire/Edit.vue'),
                meta: {
                    active: '/tables/customerQuestionnaire',
                    breadcrumb: [
                        {
                            name: '顾客调查问卷',
                            path: '/tables/customerQuestionnaire',
                        },
                        {
                            name: '新增顾客调查问卷',
                            name2: '编辑顾客调查问卷',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                // 顾客调查问卷详情
                name: '顾客调查问卷详情',
                path: '/tables/customerQuestionnaire/detail/:id',
                component: () => import('../views/tables/customerQuestionnaire/Detail.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/tables/customerQuestionnaire',
                            name: '顾客调查问卷',
                        },
                        {
                            name: '顾客调查问卷详情',
                        },
                    ],
                },
            },
            {
                // 营销中心首页
                name: '营销中心',
                path: '/marketingCenter',
                component: () => import('../views/marketingCenter/Home.vue'),
                meta: {
                    breadcrumb: [
                        {
                            name: '营销中心',
                        },
                    ],
                },
                redirect: '/marketingCenter/group',
                children: [
                    // children 中配置的路由会被渲染在 Home 的 <router-view> 中
                    {
                        name: '拼团',
                        path: '/marketingCenter/group',
                        component: () => import('../views/marketingCenter/group/Home.vue'),
                    },
                    {
                        name: '编辑拼团',
                        path: '/marketingCenter/group/edit/:id?',
                        component: () => import('../views/marketingCenter/group/Edit.vue'),
                        meta: {
                            breadcrumb: [
                                {
                                    name: '营销中心',
                                    path: '/marketingCenter',
                                },
                                {
                                    name: '拼团',
                                    path: '/marketingCenter/group',
                                },
                                {
                                    name: '编辑拼团',
                                },
                            ],
                        },
                    },
                    {
                        name: '用户列表',
                        path: '/marketingCenter/group/userList',
                        component: () => import('../views/marketingCenter/group/UserList.vue'),
                        meta: {
                            breadcrumb: [
                                {
                                    name: '营销中心',
                                    path: '/marketingCenter',
                                },
                                {
                                    name: '拼团',
                                    path: '/marketingCenter/group',
                                },
                                {
                                    name: '用户列表',
                                },
                            ],
                        },
                    },
                    {
                        name: '订单列表',
                        path: '/marketingCenter/group/orderList',
                        component: () => import('../views/marketingCenter/group/OrderList.vue'),
                        meta: {
                            breadcrumb: [
                                {
                                    name: '营销中心',
                                    path: '/marketingCenter',
                                },
                                {
                                    name: '拼团',
                                    path: '/marketingCenter/group',
                                },
                                {
                                    name: '订单列表',
                                },
                            ],
                        },
                    },
                    {
                        name: '订单详情',
                        path: '/marketingCenter/group/orderDetail/:id?',
                        component: () => import('../views/marketingCenter/group/OrderDetail.vue'),
                        meta: {
                            breadcrumb: [
                                {
                                    name: '营销中心',
                                    path: '/marketingCenter',
                                },
                                {
                                    name: '拼团',
                                    path: '/marketingCenter/group',
                                },
                                {
                                    name: '订单列表',
                                    path: '/marketingCenter/group/orderList',
                                },
                                {
                                    name: '订单详情',
                                },
                            ],
                        },
                    },
                ],
            },
            {
                //平台活动-套餐卡管理
                name: '套餐卡管理',
                path: '/platformActivity/packageCard',
                component: () => import('../views/platformActivity/packageCard/Index.vue'),
                meta: {
                    active: '/platformActivity/packageCard',
                    breadcrumb: [
                        {
                            name: '套餐卡管理',
                        },
                    ],
                },
            },
            {
                //平台活动-套餐卡详情
                name: '套餐卡详情',
                path: '/platformActivity/packageCard/detail/:id',
                component: () => import('../views/platformActivity/packageCard/Detail.vue'),
                meta: {
                    breadcrumb: [
                        {
                            path: '/platformActivity/packageCard',
                            name: '套餐卡管理',
                        },
                        {
                            name: '套餐卡详情',
                        },
                    ],
                },
            },
            {
                //平台活动-订单管理
                name: '订单管理',
                path: '/platformActivity/order',
                component: () => import('../views/platformActivity/order/Index.vue'),
                meta: {
                    active: '/platformActivity/order',
                    breadcrumb: [
                        {
                            name: '订单管理',
                        },
                    ],
                },
            },
            {
                //平台活动-订单详情
                name: '订单详情',
                path: '/platformActivity/order/detail/:id',
                component: () => import('../views/platformActivity/order/Detail.vue'),
                meta: {
                    active: '/platformActivity/order',
                    breadcrumb: [
                        {
                            path: '/platformActivity/order',
                            name: '订单管理',
                        },
                        {
                            name: '订单详情',
                        },
                    ],
                },
            },
            {
                //平台活动-中奖记录
                name: '中奖记录',
                path: '/platformActivity/packageCard/award',
                component: () => import('../views/platformActivity/packageCard/Award.vue'),
                meta: {
                    active: '/platformActivity/packageCard/award',
                    breadcrumb: [
                        {
                            name: '中奖记录',
                        },
                    ],
                },
            },
            {
                //平台活动-财务记录
                name: '财务记录',
                path: '/platformActivity/account/index',
                component: () => import('../views/platformActivity/account/Index.vue'),
                meta: {
                    active: '/platformActivity/account/index',
                    breadcrumb: [
                        {
                            name: '财务记录',
                        },
                    ],
                },
            },
            {
                //平台活动-购卡记录
                name: '购卡记录',
                path: '/platformActivity/packageCard/record/:id?',
                component: () => import('../views/platformActivity/packageCard/Record.vue'),
                meta: {
                    active: '/platformActivity/packageCard/record',
                    breadcrumb: [
                        {
                            name: '购卡记录',
                            name2: '购卡记录',
                            param: 'id',
                        },
                    ],
                },
            },
            {
                //活动管理-酷车卡订单
                name: '酷车卡订单',
                path: '/activity/kuqa/order',
                component: () => import('../views/activity/kuqa/order/Index.vue'),
                meta: {
                    active: '/activity/kuqa/order',
                    breadcrumb: [
                        {
                            name: '酷车卡订单',
                        },
                    ],
                },
            },
            {
                //活动管理-酷车卡订单详情
                name: '酷车卡订单详情',
                path: '/activity/kuqa/order/detail/:id',
                component: () => import('../views/activity/kuqa/order/Detail.vue'),
                meta: {
                    active: '/activity/kuqa/order',
                    breadcrumb: [
                        {
                            path: '/activity/kuqa/order',
                            name: '酷车卡订单',
                        },
                        {
                            name: '酷车卡订单详情',
                        },
                    ],
                },
            },
        ],
    },
    {
        path: '/login',
        name: '用户登录',
        component: () => import('../views/Login.vue'),
    },
];

const router = new VueRouter({
    routes,
});

export default router;
