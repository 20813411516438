// 会员相关接口
const install = (Vue, axios) => {

	// 保存
	let save = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/member/edit', params)
		} else {
			return axios.post('/api/merchant/member/add', params)
		}
	};
	// 获取列表
	let getMembers = (params = {}) => axios.post('/api/merchant/member/list', params)
	// 获取单条
	let getMember = (params = {}) => axios.post('/api/merchant/member/info', params)
	// 删除列表
	let del = (params = {}) => axios.post('/api/merchant/member/del', params)
	// 获取会员的服务卡列表
	let getMemberCards = (params = {}) => axios.post('/api/merchant/member/card/list', params)
    // 获取超级会员卡列表
	let getVipCards = (params = {}) => axios.post('/api/merchant/vip/getMemberVipTicket', params)
    
	// 删除列表
	let delVip = (params = {}) => axios.post('/api/merchant/member/del', params)
	// 获取账户明细
	let getAccountDetail = (params = {}) => axios.post('/api/merchant/member/order/list', params)
	// 获取会员的已购买套餐
	let getMemberGroups = (params = {}) => axios.post('/api/merchant/member/set/meal/list', params)
	// 修改会员余额
	let changeMemberBalance = (params = {}) => axios.post('/api/merchant/member/money/edit', params)
	// 获取余额变更记录
	let getBalanceLog = (params = {}) => axios.post('/api/merchant/member/money/Balance', params)
	
	
	// 挂载
	Vue.prototype.$api.member = {
        getVipCards,
		save,
		del,
		delVip,
		getMembers,
		getMember,
		getMemberCards,
		getMemberGroups,
		getAccountDetail,
		changeMemberBalance,
		getBalanceLog
	};
}

export default {
	install
}