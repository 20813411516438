// 用户相关接口
const install = (Vue, axios) => {
	// 角色相关
	// 获取角色列表
	let getRoles = (params = {}) => axios.post('/api/merchant/role/list', params);
	// 保存角色
	let saveRole = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/role/edit', params)
		} else {
			return axios.post('/api/merchant/role/add', params)
		}
	};
	// 删除角色
	let delRole = (params = {}) => axios.post('/api/merchant/role/del', params);
	// 获取角色
	let getRole = (params = {}) => axios.post('/api/merchant/role/info', params);


	// 权限相关
	// 获取权限列表
	let getPowers = (params = {}) => axios.post('/api/merchant/permissions/list', params);
	// 保存权限
	let savePower = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/permissions/edit', params)
		} else {
			return axios.post('/api/merchant/permissions/add', params)
		}
	};
	// 获取权限
	let getPower = (params = {}) => axios.post('/api/merchant/permissions/info', params);

	// 飞鹅打印配置
	// Content-Type: application/x-www-form-urlencoded
	let savePrinter = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/printer/edit', params)
		} else {
			return axios.post('/api/merchant/printer/add', params)
		}
	}
	// 获取打印机信息
	let getPrinter = (params = {}) => axios.post('/api/merchant/printer/info', params);
	// 测试打印功能
	let testPrint = (params = {}) => axios.post('/api/merchant/check/printer/status', params);

	// 查询帮助列表
	let getHelps = (params = {}) => axios.get('/api/merchant/help/index', params);
	// 查询帮助详情
	let getHelpDetail = (params = {}) => axios.get('/api/merchant/help/detail', {
		params
	});
	// 获取卡项
	let actList = (params = {}) => axios.post('/api/merchant/signin/actList', params);
	//会员卡类型
	let hYList = (params = {}) => axios.post('/api/merchant/vip/list', params);
	//采购总价格
	let calculate = (params = {}) => axios.post('/api/merchant/vip/calculate', params);
	//采购支付
	let pay = (params = {}) => axios.post('/api/merchant/vip/pay', params);

	//商家购买记录
	let payList = (params = {}) => axios.post('/api/merchant/vip/payList', params);

	//用户购买记录
	let orderList = (params = {}) => axios.post('/api/merchant/vip/orderList', params);
	
	//轮询
	let loopPay = (params = {}) => axios.post('/api/merchant/vip/loopPay', params);
	
	//收益统计
	let stat = (params = {}) => axios.post('/api/merchant/vip/stat', params);
	
	//查看记录
	let statlog=(params = {}) => axios.post('/api/merchant/vip/statlog', params);
	
	//会员详情
	let getActInfo=(params = {}) => axios.post('api/merchant/vip/getActInfo', params);

	//兑换码列表
	let getCodeKeyList=(params = {}) => axios.post('api/merchant/signin/cdkeyList', params);
	//生成兑换码
	let addCdkey=(params = {}) => axios.post('api/merchant/signin/addCdkey', params);
	// 获取购买卡项的会员
	let actLog = (params = {}) => axios.post('/api/merchant/signin/actLog', params);
	// 获取购买卡项的会员的打卡记录
	let userActLog = (params = {}) => axios.post('/api/merchant/signin/userActLog', params);
	// 获取打卡记录
	let logList = (params = {}) => axios.post('/api/merchant/signin/logList', params);
	// 获取打卡码
	let getCode = (params = {}) => axios.post('/api/merchant/signin/getCode', params);
	// 更新打卡码
	let updateCode = (params = {}) => axios.post('/api/merchant/signin/updateCode', params);
	// 获取购买活动的用户
	let getUserByAct = (params = {}) => axios.post('/api/merchant/signin/getUserByAct', params);

	// 挂载
	Vue.prototype.$api.system = {
		getRoles,
		saveRole,
		delRole,
		getRole,
		getPowers,
		savePower,
		getPower,
		savePrinter,
		getPrinter,
		testPrint,
		getHelpDetail,
		getHelps,
		actList,
		actLog,
		logList,
		getCode,
		updateCode,
		userActLog,
		getUserByAct,
		hYList,
		calculate,
		pay,
		payList,
		loopPay,
		orderList,
		stat,
		statlog,
		getActInfo,
		getCodeKeyList,
		addCdkey
	};
}

export default {
	install
}
