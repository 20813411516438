// 用户相关接口
const install = (Vue, axios) => {

	// 获取验证码
	let getCheckCode = (params = {}) => axios.post('/api/captcha', params);
	// 登录
	let login = (params = {}) => axios.post('/api/merchant/admin/login', params);
	//修改密码
	let changePwd = (params = {}) => axios.post('/api/merchant/admin/reset/pwd', params)
	// 获取用户列表
	let getUsers = (params = {}) => axios.post('/api/merchant/admin/list', params);
	// 获取用户
	let getUser = (params = {}) => axios.post('/api/merchant/admin/info', params);
	// 删除用户
	let del = (params = {}) => axios.post('/api/merchant/admin/del', params);
	// 保存用户
	let save = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/admin/edit', params)
		}else{
			return axios.post('/api/merchant/admin/add', params)
		}
	};


	// 挂载
	Vue.prototype.$api.user = {
		getCheckCode,
		login,
		getUsers,
		getUser,
		del,
		save,
		changePwd
	};
}

export default {
	install
}
