const install = (Vue, vm) => {
	/**
	 * 金额转大写
	 * @param {number} n 数字
	 */
	const convertCurrency = (n) => {
		if (n == 0) {
			return "零";
		}
		if (!(/^(\+|-)?(0|[1-9]\d*)(\.\d+)?$/.test(n)))
			return "数据非法";
		var unit = "仟佰拾亿仟佰拾万仟佰拾元角分",
			str = "";
		n += "00";
		var a = parseFloat(n);
		if (a < 0) {
			n = n.substr(1);
		}
		var p = n.indexOf('.');
		if (p >= 0) {
			n = n.substring(0, p) + n.substr(p + 1, 2);
		}
		unit = unit.substr(unit.length - n.length);
		for (var i = 0; i < n.length; i++)
			str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
		if (a > 0) {
			return str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g,
				"$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
		} else {
			return "负" + str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g,
				"$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
		}
	}



	/**
	 * 随机数
	 * @param {number} minNum 
	 * @param {number} maxNum 
	 */
	const randomNum = (minNum, maxNum) => {
		return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
	}
	/**
	 * 浮点数保留2为小数
	 * @param {number} x 
	 */
	const toDecimal = (x) => {
		var f = parseFloat(x);
		if (isNaN(f)) {
			return;
		}
		f = Math.round(x * 100) / 100;
		return f;
	}

	/**
	 * 对象转url参数
	 * @param {object}} obj 对象
	 */
	const objToUrl = obj => {
		let queryArr = [];
		for (let i in obj) {
			queryArr.push(i + '=' + obj[i]);
		}
		return queryArr.join('&');
	}
	// 判断数据类型
	const checkType = data => {
		return Object.prototype.toString.call(data).slice(8, -1);
	}
	// 对象深拷贝
	const deepClone = target => {
		let targetType = checkType(target);
		let result;
		if (targetType === 'Object') {
			result = {};
		} else if (targetType === 'Array') {
			result = [];
		} else {
			return target;
		}
	
		for (let i in target) {
			let value = target[i];
			let valueType = checkType(value);
			if (valueType === 'Object' || valueType === 'Array') {
				result[i] = deepClone(value);
			} else {
				result[i] = value;
			}
		}
		return result;
	}
	// 将对象的空字符串属性转为undefined
	const emptyToUndefined = obj => {
		let keys = Object.keys(obj)
		keys.forEach((item) => {
			if (obj[item] === '') {
				obj[item] = undefined
			}
		})
		return obj;
	}

	const getWaitCount = () => {
		// 获取待处理数量
		vm.$api.statistics.getPendingCount().then(res => {
			vm.$vuex('vuex_waitgoods', res.data.waitGoods)
			vm.$vuex('vuex_waitend', res.data.waitEnd)
		})
	}
	// 数字转金额，保留两位小数
	const toMoney = (num) => {
		num = Number(num).toFixed(2).split('.');
		let a = Number(num[0]).toLocaleString()
		return a + '.' + num[1]
	}
	// 手机号码加*
	const phoneStar = (tel) => {
		return tel ? tel.substr(0, 3) + '****' + tel.substr(7) : '';
	}
	
	// 挂载
	Vue.prototype.$util = {
		convertCurrency,
		randomNum,
		toDecimal,
		objToUrl,
		deepClone,
		emptyToUndefined,
		getWaitCount,
		toMoney,
		phoneStar
	}
}

// 重写toFixed函数
Number.prototype.toFixed = function(m) {
	let result = Math.round(Math.pow(10, m) * this) / Math.pow(10, m);
	result = String(result);
	if (result.indexOf(".") == -1) {
		if (m != 0) {
			result += ".";
			result += new Array(m + 1).join('0');
		}
	} else {
		let arr = result.split('.');
		if (arr[1].length < m) {
			arr[1] += new Array(m - arr[1].length + 1).join('0')
		}
		result = arr.join('.')
	}
	return result;
}

export default {
	install
}
