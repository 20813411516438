// 项目相关接口
const install = (Vue, axios) => {


	// 保存
	let save = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/offline/server/edit', params)
		} else {
			return axios.post('/api/merchant/offline/server/add', params)
		}
	};
	// 获取自营项目列表
	let getProjects = (params = {}) => axios.post('/api/merchant/offline/server/list', params)
	// 获取单条
	let getProject = (params = {}) => axios.post('/api/merchant/offline/server/info', params)
	// 删除列表
	let del = (params = {}) => axios.post('/api/merchant/offline/server/del', params)
	
	
	// 获取品牌项目列表
	let getBrandProjects = (params = {}) => axios.post('/api/merchant/brand/service/list', params)
	// 修改品牌项目上下架
	let setBrandStatus = (params = {}) => axios.post('/api/merchant/brand/service/status/edit', params)
	// 修改销售提成
	let saveSaleCommission = (params = {}) => axios.post('/api/merchant/set/brand/service/sale/commission', params)
	// 修改品牌项目信息
	let saveBrandInfo = (params = {}) => axios.post('/api/merchant/brand/service/edit', params)
	
	

	// 获取父子结构的品牌分类服务
	let getClassifyBrandServe = (params = {}) => axios.post('/api/merchant/brand/classify/serve/list', params)
	// 获取福字结构的自营分类服务
	let getClassifySelfServe = (params = {}) => axios.post('/api/merchant/self/classify/serve/list', params)
	

	// 挂载
	Vue.prototype.$api.project = {
		save,
		del,
		getProjects,
		getProject,
		getBrandProjects,
		setBrandStatus,
		getClassifyBrandServe,
		getClassifySelfServe,
		saveSaleCommission,
		saveBrandInfo
	};
}

export default {
	install
}
