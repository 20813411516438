const install = (Vue, axios) => {
    // 创建订单
    let create = (params = {}) => axios.post('/api/merchant/order/create', params);
    // 创建订单(修复数据使用)
    let create2 = (params = {}) => axios.post('/api/merchant/order/create2', params);
    // 订单列表
    let getOrders = (params = {}) => axios.post('/api/merchant/order/list', params);

    // 根据订单核销码或订单id，查询订单
    let getOrderByIdOrCode = (params = {}) => axios.post('/api/merchant/order/get/info', params);
    // 核销订单
    let useOrder = (params = {}) => axios.post('/api/merchant/order/cancellation', params);

    // 线上下单，到门店自提的相关接口
    // 获取自提订单列表
    let getDeliveryOrders = (params = {}) => axios.post('/api/merchant/self/delivery/order/list', params);
    // 根据订单核销码或订单id，查询订单
    let getDeliveryOrderByIdOrCode = (params = {}) => axios.post('/api/merchant/self/delivery/order/info', params);
    // 核销订单
    let useDeliveryOrder = (params = {}) => axios.post('/api/merchant/self/delivery/order/cancellation', params);
    // 退款
    let refund = (params = {}) => axios.post('/api/merchant/order/refund', params);
    // 订单导出
    let orderExport = (params = {}) => axios.post('/api/merchant/order/orderExport', params, { responseType: 'blob' });

    // 挂载
    Vue.prototype.$api.order = {
        create,
        create2,
        getOrders,
        getOrderByIdOrCode,
        useOrder,
        getDeliveryOrders,
        getDeliveryOrderByIdOrCode,
        useDeliveryOrder,
        refund,
        orderExport,
    };
};

export default {
    install,
};
