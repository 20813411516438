// 套餐相关接口
const install = (Vue, axios) => {
	

	// 保存
	let save = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/set/meal/edit', params)
		} else {
			return axios.post('/api/merchant/set/meal/add', params)
		}
	};
	// 获取套餐列表，自营 type 1，品牌 type 2， serve_brand_id：品牌id type为2时传
	let getGroups = (params = {}) => axios.post('/api/merchant/set/meal/list', params)
	// 获取分类
	let getClassify = (params = {}) => axios.post('/api/merchant/set/meal/classify', params)
	// 获取单条
	let getGroup = (params = {}) => axios.post('/api/merchant/set/meal/info', params)
	// 删除列表
	let del = (params = {}) => axios.post('/api/merchant/set/meal/del', params)
	// 修改品牌套餐上下架 id、status（0 停用 1 启用）
	let publishBrandGroup = (params = {}) => axios.post('/api/merchant/brand/set/meal/edit', params)
	
	
	// 规格
	// 保存
	let saveItem = (params = {}) => {
		if (params.id) {
			return axios.post('/api/merchant/set/meal/specs/edit', params)
		} else {
			return axios.post('/api/merchant/set/meal/specs/add', params)
		}
	};
	// 获取规格列表
	let getGroupItems = (params = {}) => axios.post('/api/merchant/set/meal/specs/list', params)
	// 获取规格
	let getGroupItem = (params = {}) => axios.post('/api/merchant/set/meal/specs/info', params)
	
	// 获取品牌规格
	let getBrandGroupItems = (params = {}) => axios.post('/api/merchant/brand/set/meal/specs/list', params)
	// 发布品牌规格
	let publishBrandGroupItem = (params = {}) => axios.post('/api/merchant/brand/set/meal/specs/edit', params)
	// 获取单条规格详情
	let getBrandGroupItem = (params = {}) => axios.post('/api/merchant/brand/set/meal/specs/info', params)
	
	// 根据ids [] 获取多个规格详情 
	let getSpecDetails = (params = {}) => axios.post('/api/merchant/specs/get/info', params)
	
	

	// 挂载
	Vue.prototype.$api.group = {
		getSpecDetails,
		getGroups,
		getClassify,
		save,
		getGroup,
		getGroupItems,
		getGroupItem,
		saveItem,
		publishBrandGroup,
		getBrandGroupItems,
		getBrandGroupItem,
		publishBrandGroupItem,
		del
	};
}

export default {
	install
}
