const install = (Vue, axios) => {
    // 挂载
    Vue.prototype.$api['platformActivity'] = {
        /**
         * 获取套餐卡列表
         */
        getActivityList: (params = {}) => axios.post('api/merchant/activity/list', params),
        /**
         * 获取参与的套餐卡列表
         */
        getPCList: (params = {}) => axios.post('api/merchant/activity_merchant/list', params),
        /**
         * 获取订单列表
         */
        getOrderList: (params = {}) => axios.post('api/merchant/activity_order/list', params),
        /**
         * 订单详情
         */
        getOrderDetail: (params = {}) => axios.post('api/merchant/activity_order/detail', params),
        /**
         * 抽奖数据
         */
        getStatistics: (params = {}) => axios.post('api/merchant/activity_statistics/list', params),
        /**
         * 分享记录列表
         */
        getShareRecord: (params = {}) => axios.post('api/merchant/activity_share/list', params),
        /**
         * 中奖记录列表
         */
        getAwardRecord: (params = {}) => axios.post('api/merchant/activity_award/list', params),
        /**
         * 核销确认
         */
        checkConfirm: (params = {}) => axios.post('api/merchant/activity_order/checkOrderConfirm', params),
        /**
         * 核销操作
         */
        checkAction: (params = {}) => axios.post('api/merchant/activity_order/checkOrder', params),
        /**
         * 奖品核销确认
         */
        checkAwardConfirm: (params = {}) => axios.post('api/merchant/activity_order/checkAwardConfirm', params),
        /**
         * 奖品核销操作
         */
        checkAward: (params = {}) => axios.post('api/merchant/activity_order/checkAward', params),
        /**
         * 财务记录
         */
        getAccountList: (params = {}) => axios.post('api/merchant/activity_merchant_account/list', params),
        /**
         * 购卡订单列表
         */
        getMerchantOrderList: (params = {}) => axios.post('api/merchant/activity_merchant_order/list', params),
        /**
         * 商户的 提现操作
         */
        withdraw: (params = {}) => axios.post('api/merchant/activity_merchant_withdraw/withdraw', params),
        /**
         * 在线购卡，下单操作
         */
        createOrder: (params = {}) => axios.post('api/merchant/activity_merchant_order/order', params),
        /**
         * 在线购卡，检查是否支付成功
         */
        checkPay: (params = {}) => axios.post('api/merchant/activity_merchant_order/check_pay', params),
    };
};

export default {
    install,
};
